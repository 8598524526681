<template>
  <div class="flex justify-between">
    <div class="w-6/12">
      <div class="form-row ">
            <div class="form-col">
                <label for="transfer_date">transfer effective date</label>
                <date-picker name="transfer_date" ></date-picker>
            </div>
          </div>
          <div class="form-row ">
            <div class="form-col">
                <label for="start_date">lease start date</label>
                <date-picker name="start_date" ></date-picker>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
                <label for="end_date">lease end date</label>
                <date-picker name="end_date" ></date-picker>
            </div>
          </div>
          <div class="form-row ">
            <div class="form-col">
              <label for="rent_amount">rent amount</label>
              <div class="flex justify-between">
                <div class="w-full">
                  <input  type="number" class="form-input" id="rent_amount"/>
                </div>
                <!-- <div  class="">
                  <div>
                    <button
                      class="flex items-center border-b font-bold focus:outline-none ml-4 mt-2 button-color"
                      type="button"
                      >
                      <icon name="plus" class="w-5 h-5 mr-1 mb-1 color-text" /> add additional charges 
                    </button>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="form-row ">
            <div class="form-col  ">
              <label for="rent_amount">security deposit amount</label>
              <input  type="number" class="form-input" id="rent_amount"/>
            </div>
          </div>
          <div class=" flex pt-10">
            <p class="font-bold mr-2 mt-1">transfer balance?</p> 
            <toggle-input />
          </div>
          <div class=" flex pt-10">
            <p class="font-bold mr-2 mt-1">percentage of balance</p> 
            <div class="w-24 flex">
              <input  type="number" class="form-input" id="percentage_balance"/>
                <p class="font-bold ml-2 mt-2">%</p> 
            </div>
          </div>
    </div>
    <div class="w-6/12">
      <div class=" flex pt-10 justify-end">
        <p class="font-bold mr-2 mt-1">transfer recurring charges?</p> 
        <toggle-input />
      </div>
      <div class=" flex pt-10 justify-end">
        <p class="font-bold mr-2 mt-1">copy recurring charges?</p> 
        <toggle-input />
      </div>
      <div class="flex justify-end pt-10">
        <div>
          <label class="flex justify-between w-full mb-8">
            <input
              type="checkbox"
              class="w-4 h-4 mt-1"
            />
            <div class="flex-1 mx-2">
              <p  class="">  
                recurring charge 1
              </p>
            </div>
          </label>
        </div>
        <div>
          <input  type="number" class="form-input" id="rent_amount"/>
        </div>
      </div>
         <div class="flex justify-end ">
        <div>
          <label class="flex justify-between w-full mb-8">
            <input
              type="checkbox"
              class="w-4 h-4 mt-1"
            />
            <div class="flex-1 mx-2">
              <p  class="">  
                recurring charge 2
              </p>
            </div>
          </label>
        </div>
        <div class="mb-4">
          <input  type="number" class="form-input" id="rent_amount"/>
        </div>
      </div>
    </div>
    <modal-footer :footer="footer" :primary="save" :secondary="cancel"/>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import NotifyMixin from "@/mixins/NotifyMixin";
// import Icon from '@/components/ui/Icon';
import ToggleInput from "@/components/ui/ToggleInput";
import ModalFooter from '@/components/ui/modals/ModalFooter'
import DatePicker from "@/components/ui/datepicker/DatePicker";

export default {
  name:'transfer resident',
  mixins: [
    ModalNavigation,
    NotifyMixin
  ],
  components:{
    // Icon,
    ToggleInput,
    ModalFooter,
    DatePicker
  },
  data() {
    return {
      footer: {
        primaryButton: 'create lease',
        secondaryButton: 'back',
      },
    }
  },
  methods: {
    save(){
      this.$router.push({name: 'transfer_resident.index'});
    },
    cancel(){
      this.$router.push({name: 'transfer_resident.index'});
    },    
  }
}
</script>

<style scoped>
.button-color {
  border-color: var(--highlightColor500);
  color: var(--highlightColor500);
}
</style>